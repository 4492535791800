// components
import Seo from 'src/components/Seo';
// hooks
import useDedicatedTeamMetaData from 'src/cms/data/dedicatedTeam/useDedicatedTeamMetaData';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  const dedicatedTeamMetaData = useDedicatedTeamMetaData();

  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={dedicatedTeamMetaData?.title}
      description={dedicatedTeamMetaData?.description}
      schema={dedicatedTeamMetaData?.schema}
    />
  );
};

export { default } from './DedicatedTeam';
