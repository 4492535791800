import loadable from '@loadable/component';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// hooks
import useDedicatedTeamIntroData from 'src/cms/data/dedicatedTeam/useDedicatedTeamIntroData';
import { FC } from 'react';
// sections
const Team = loadable(() => import('./sections/Team'));
const Process = loadable(() => import('./sections/Process'));
const CostEffectiveness = loadable(() =>
  import('./sections/CostEffectiveness')
);
const Benefits = loadable(() => import('./sections/Benefits'));

import { PageProps } from 'gatsby';

const DedicatedTeam: FC<PageProps> = () => {
  // hooks
  const { title, mobileTitle, description } = useDedicatedTeamIntroData();

  return (
    <PageLayout>
      <Intro
        title={title}
        mobileTitle={mobileTitle}
        description={description}
        icon='dedicatedTeamDrawing'
      />
      <Team />
      <Process />
      <CostEffectiveness />
      <Benefits />
    </PageLayout>
  );
};

export default DedicatedTeam;
